<template>
    <b-tabs
        pills
    >

<!--            FINANCES-->
        <b-tab
            :title="i18nT(`Finances`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="coins"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Finances`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Finances`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 m-0 pt-25 pb-25">{{ i18nT(`Revenus`) }} <br></h6>
                                    </div>
                                    <h3
                                        class="m-0"
                                        v-if="currentLease.BuildingInitialPrice"
                                    >
                                        {{ {amount: 14000} | currency }}
                                    </h3>
                                    <div class="mb-50" v-if="!currentLease.BuildingInitialPrice">
                                        <h3 class="m-0"
                                        >
                                            {{ '0.00 '}}
                                        </h3>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            md="6"
                            cols="12"
                            v-if="currentLease.BuildingInitialPrice"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 m-0 pt-25 pb-25">{{ i18nT(`En attente`) }} <br></h6>
                                    </div>
                                    <h3
                                        class="m-0 text-warning"
                                    >
                                        {{ {amount: 10000} | currency}}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1 d-flex align-items-center">
                                    <div class="d-flex justify-content-center align-items-center mr-2">
                                        <b-avatar size="6em" variant="light-warning">
                                            <RtIcon variant="thin" icon="coins" :size="37"/>
                                        </b-avatar>
                                    </div>
                                    <div>
                                        <h6>{{ i18nT(`Solde locataires`) }}</h6>
                                        <h1
                                            class="m-0"
                                            :class="stats.TotalNetIncome  >= 0 ? 'text-primary' : 'text-danger'"
                                        >
                                            {{ {amount: -15000 } | currency }}
                                        </h1>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div class="mt-1 d-flex justify-content-around">
                                <b-button class="w-100 mr-1" variant="outline-primary">{{ i18nT(`Finances`)}}</b-button>
                                <b-button class="w-100" variant="outline-primary">{{ i18nT(`Détail du solde`)}}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>

<!--            MAINTENANCE-->
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="screwdriver-wrench"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Interventions`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Interventions`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in maintenance"
                                :key="`maintenance` + index"
                                class="pt-1"
                            >
                                <maintenance-card :maintenance="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>


<!--            NOTES-->
        <b-tab
            :title="i18nT(`Notes`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="sticky-note"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Notes`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Notes`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(note, index) in notes"
                            :key="'note' + index"
                        >
                            <note-card :note="note" @delete="deleteNote(index)" />
                            <!--                            <b-card no-body border-variant="primary">-->
                            <!--                                <b-card-body :class="`note-bg-${note.NoteColour}`">-->
                            <!--                                    <b-row>-->
                            <!--                                        <b-col-->
                            <!--                                            cols="12"-->
                            <!--                                            md="1"-->
                            <!--                                            class="text-center"-->
                            <!--                                        >-->
                            <!--                                        <rt-icon icon="note-sticky" variant="thin" :size="40" />-->
                            <!--                                        </b-col>-->
                            <!--                                        <b-col-->
                            <!--                                            md="10"-->
                            <!--                                            cols="12"-->
                            <!--                                            class="pl-2"-->
                            <!--                                        >-->
                            <!--                                            <h5 class="cursor-pointer text-truncate w-75 mb-0">{{ note.NoteText }}</h5>-->
                            <!--                                            <small>{{ note.NoteCreateTime | date }}</small>-->
                            <!--                                        </b-col>-->
                            <!--                                        <b-col-->
                            <!--                                            cols="12"-->
                            <!--                                            md="1"-->
                            <!--                                            sm="12"-->
                            <!--                                        >-->
                            <!--                                            <b-button-->
                            <!--                                                variant="warning"-->
                            <!--                                                class="pill float-right ml-1"-->
                            <!--                                                @click="deleteNote(index)"-->
                            <!--                                            >-->
                            <!--                                                <rt-icon icon="trash text-danger" variant="regular"/>-->
                            <!--                                            </b-button>-->
                            <!--                                        </b-col>-->
                            <!--                                    </b-row>-->
                            <!--                                </b-card-body>-->
                            <!--                            </b-card>-->
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                :label="i18nT(`Nouvelle note`)"
                            >
                                <b-form-textarea
                                    :id='i18nT(`Nouvelle note`)'
                                    v-model="newNote"
                                    :rows="4"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button
                                class="float-right mb-1"
                                :disabled="newNote.length <= 0"
                                variant="outline-primary"
                                @click="saveNewNote"
                            >
                                {{ i18nT(`Sauvegarder`) }}
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>

<!--        INSURANCE-->
        <b-tab
            :title="i18nT(`Assurances`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="briefcase"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Assurances`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Assurances`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in insurances"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                            >
                                <document-card :document="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>



<!--        DOCUMENTS-->
        <b-tab
            :title="i18nT(`Documents`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="briefcase"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Documents`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Documents`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in documents"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                            >
                                <document-card :document="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>

<!--        ACTIVITY-->
        <b-tab
            :title="i18nT(`Activité`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="clock-rotate-left"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Activité`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Historique`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                            <app-timeline>
                                <app-timeline-item
                                    v-for="(log, index) in logs"
                                    :key="index + 'log'"
                                    :title="log.ActionText"
                                    :subtitle="'Email: ' + log.LogUserEmail"
                                    :icon="getTimelineIcon(log.LogAction)"
                                    :variant="getTimelineVariant(log.LogAction)"
                                    :time="formatDate(log.CreatedAt)"
                                    :showDelete="false"
                                />
                            </app-timeline>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <div class="ps__rail-y">
            <div class="ps__thumb-y"></div>
        </div>
    </b-tabs>
</template>




<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import VueApexCharts from 'vue-apexcharts'
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import MaintenanceCard from '@/views/components/maintenanceCard/MaintenanceCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@/views/components/activityTimelineItem/ActivityTimelineItem.vue'
import TaskCard from "@/views/components/taskCard/TaskCard.vue";
import NoteCard from "@/views/components/noteCard/NoteCard.vue";
import vSelect from 'vue-select'

export default{
    components:{
        NoteCard,
        TaskCard,
        DocumentCard,
        ContactCard,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        MaintenanceCard,
        AppTimeline,
        AppTimelineItem,
        VueApexCharts,
        vSelect,
    },
    directives:{
        'b-tooltip': VBTooltip
    },
    data(){
        return {
            stats: {},
            maintenance: [],
            insurances: [],
            notes: [],
            newNote: '',
            documents: [],
            logs: [],
            currentLeaseId: router.currentRoute.params.id,
            currentLease: {},
        }
    },
    props: {
        leaseData: {
            type: Object,
            required: true
        }
    },
    created() {
    },
    methods: {
        getTimelineIcon(action) {
            if (action === 'insert') {
                return 'plus'
            }
            else if (action === 'update') {
                return 'arrow-up-from-bracket'
            }
            else if (action === 'execArchive') {
                return 'box-archive'
            }
            else if (action === 'execUnarchive') {
                return 'box-archive'
            }
            else if (action === 'deleteRestore') {
                return 'arrows-rotate'
            }
            else if (action === 'deleteTrash') {
                return 'trash'
            }
        },
        getTimelineVariant(action) {
            if(action === 'insert' || action === 'update') {
                return 'primary'
            } else if (action === 'execUnarchive' || action === 'deleteRestore') {
                return 'info'
            }
            else if (action === 'execArchive') {
                return 'warning'
            }
            else {
                return 'danger'
            }
        },
        formatDate(date) {
            let dateFormat = useAuth.getDateFormat() !== (null || 'null') ? useAuth.getDateFormat() : 'DD/MM/YYYY';
            dateFormat += ' HH:mm:ss'
            const formatted = moment(new Date(date)).format(dateFormat);

            return formatted;
        },
        deleteNote(index) {
            // TODO add call request when api is ready or push to parent comp

            this.notes.splice(index, 1)
        },
        saveNewNote() {
            // TODO add call request when api is ready or push to parent comp

            this.notes.push({
                NoteText: this.newNote,
                NoteCreateTime: new Date(),
                NoteColour: 'yellow',
            })
            this.newNote = ''
        },
    },
    watch: {
        leaseData: {
            handler: function(val) {
                if(val) {
                    this.currentLease = val.lease
                    this.maintenance = val.maintenance
                    this.notes = val.notes
                    this.documents = val.documents
                    this.insurances = val.insurances
                    this.logs = val.logs
                }
            },
            deep: true
        },
    },
}
</script>


<style lang="scss">

.rt-tabs-list{
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
}

.rt-tabs-list .nav-link.active {
    box-shadow: none;
}

.rt-tabs-list::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

.rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 20px;
}

// .rt-tabs-list:hover::-webkit-scrollbar-track {
//     background: #eee;
//     border-radius: 20px;
// }

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #b4b7bd;
}

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-track {
    background: #6e6b7b;
}



</style>