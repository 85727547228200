<template>
    <div v-if="leaseDetail">
        <ellipsis-dropdown
            componentName="view-lease"
            :showDeleteBtn="leaseId !== undefined"
            @save="onEdit"
            @delete="onDelete"
            :itemId="leaseId"
        />
        <div class="d-inline-flex justify-content-start align-items-center mb-1 ml-50">
            <span @click="$router.push({name:'leases'})" class="cursor-pointer d-flex align-items-center">
                <RtIcon icon="chevron-left" variant="light" :size="20" />
            </span>
        <h3 class="ml-1 mb-0">{{leaseDetail.LeaseTitle}}</h3>
        </div>
        <b-row>
            <b-col 
                cols="12"
                md="6"
            >
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Informations`) }}</h4>
                    </b-card-header>

                    <b-card-body>
                        <b-row>
                            <b-col cols="12">
                                <h3>{{ leaseDetail.LeaseTitle }}</h3>
                                <p>{{ leaseDetail.LeaseStartDate | date }} - {{ leaseDetail.LeaseEndDate | date}}</p>
                                <b-badge
                                    :variant="leaseDetail.LeaseActive == 1 ? 'light-success' : 'light-secondary'"
                                    class="badge-pill p-50r mb-1"
                                    v-b-tooltip="leaseDetail.LeaseActive == 1 ? i18nT(`Le location est active`) : i18nT(`Active la location`)"
                                >
                                    {{leaseDetail.LeaseActive == 1 ? i18nT(`Active`) : i18nT(`Inactive`)}}
                                </b-badge>
                                <b-card no-body class="ren-default-card">
                                    <b-card-body class="px-1 py-1 d-flex align-items-center">
                                        <div>
                                            <b-avatar size="6em" variant="light-warning">
                                                <RtIcon variant="thin" icon="coins" :size="37"/>
                                            </b-avatar>
                                        </div>
                                        <div class="pl-1">
                                            <h6>{{ i18nT(`Loyer CC`) }}</h6>
                                            <h1 
                                                class="m-0 text-primary"
                                            >
                                                {{ {amount: parseInt(leaseDetail.LeaseMonthlyAmount || (+leaseDetail.LeaseRentHC + +leaseDetail.LeaseMaintenance)) } | currency }}
                                            </h1>
                                            <span><b>{{i18nT(`Loyer HC`)}}:</b> {{{amount: parseInt(leaseDetail.LeaseRentHC) } | currency }}</span>
                                            <span class="ml-1"><b>{{i18nT(`Charges`)}}:</b> {{{amount: parseInt(leaseDetail.LeaseMaintenance) } | currency }}</span>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-alert
                                    variant="secondary"
                                    show
                                >
                                    <div class="alert-body">
                                            <span>
                                                <b-badge variant="primary">
                                                  {{ i18nT(`Info` ) }}
                                                </b-badge>
                                                 {{i18nT(`Commencez à accepter les paiements en ligne par carte de crédit et virement bancaire.
                                                          Les paiements collectés seront versés directement sur le compte du bailleur et le loyer correspondant sera marqué comme payé.`)}}
                                                <b-link variant="info">{{i18nT(`Cliquez ici.`)}}</b-link>
                                            </span>
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Adresse`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="leaseDetail.LeaseOtherQuittanceAddress"
                                        class="ren-info-cards"
                                    >
                                        <div class="d-flex flex-column">
                                            <p class="m-0">{{ leaseDetail.BuildingActualAddress }}
                                            {{ leaseDetail.BuildingActualAddress2 }}</p>
                                            <p class="m-0">{{ leaseDetail.BuildingActualZip }} {{ leaseDetail.BuildingActualCity }}</p>
                                            <p class="m-0">{{ leaseDetail.BuildingActualCountry }}</p>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Propriétaire`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="leaseDetail.LeaseTitle"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0 mb-1">{{ leaseDetail.LeaseTitle }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Bail`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                    >
                                        <div class="d-flex flex-column mb-1">
                                            <p class="m-0">{{ i18nT(`Type`) }} {{ leaseDetail.BuildingActualAddress }}</p>
                                            <p class="m-0">{{ i18nT(`Durée`) }} {{ leaseDetail.BuildingActualZip }} {{ leaseDetail.BuildingActualCity }}</p>
                                            <p class="m-0">{{ i18nT(`Renouvellement`) }} {{ leaseDetail.BuildingActualCountry }}</p>
                                            <p class="mb-0">{{ i18nT(`Date de quittancement`) }} {{ leaseDetail.BuildingActualCountry }}</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Locataire`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="leaseDetail.TenantName"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <b-link :to="{name:'view-tenant', params: {id: leaseDetail.TenantID}}">
                                            {{i18nT(leaseDetail.TenantName)}}
                                        </b-link>
                                        <p>{{ leaseDetail.tenantEmail }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas de Biens`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Loyer`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="leaseDetail.LeaseMonthlyAmount"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <div class="d-flex flex-column">
                                            <p class="m-0"><b>{{ i18nT(`Loyer CC`) }}:</b> {{
                                                    {amount: parseInt(leaseDetail.LeaseMonthlyAmount)} | currency }}</p>
                                            <p class="m-0"><b>{{ i18nT(`Charges`) }}:</b> {{
                                                    {amount: parseInt(leaseDetail.LeaseMaintenance)} | currency}}</p>
                                            <p class="m-0"><b>{{ i18nT(`Paiemen`) }}:</b> {{ leaseDetail.LeaseBillingPeriod }}</p>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas de Biens`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Dépôts`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="leaseDetail.LeaseSecurityDeposit"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <div class="d-flex flex-column">
                                            <p class="m-0"><b>{{ i18nT(`Security deposit`) }}:</b> {{ {amount: parseInt(leaseDetail.LeaseSecurityDeposit)} | currency }}</p>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas de Biens`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <tenancy-detail-tabs :lease-data="leaseData"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BBadge,
    BLink,
    VBTooltip, BAvatar,
} from 'bootstrap-vue'
import router from '@/router'
import EllipsisDropdown from '@core/components/EllipsisDropdown.vue'
import TenancyDetailTabs from "@/views/landlord/Tenancies/TenancyDetailTabs.vue";

export default {
    components: {
        BAvatar,
        TenancyDetailTabs,
        BButton,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BTabs,
        BTab,
        BBadge,
        BLink,
        EllipsisDropdown,
    },
    directives: {
      'b-tooltip': VBTooltip
    },
    data() {
        return {
            leaseDetail: {},
            leaseId: router.currentRoute.params.id,
            leaseData: {},
        }
    },
    created() {

        this.$http.get(`landlord/leases?id=${this.leaseId}`)
        .then(({data}) => {
            console.log("data: ", data.data)
            this.leaseDetail = data.data.lease
        })
    },
    methods: {
        onEdit() {
            // TODO
        },
        onDelete() {
            // TODO
        },
        getTypeName(id){
            // const type = this.tenancyTypes.find(item => item.value == id)
            // return type ? type.text : ''
        },
    }
}

</script>